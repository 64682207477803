// REACT
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { motion, MotionConfig } from "framer-motion";
// COMPONENTS
import Carousel from '../../components/Carousel/Carousel';
// SCSS
import classes from './Home.module.scss';


interface Props {
    name: string,
    logo: string
}

interface Page{
    company: string;
    slogan: string;
    title: string;
    carousel: string[];
    section: {
        image: string;
        title: string;
        text: string
    }[]
}

const Home: React.FC<Props> = props => {

    const { name, logo } = props;

    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [page, pageHandler] = useState<Page[]>(
        []
    );

    const fetchData = async () => {
        const response = await axios('/home.json');
        pageHandler(response.data);
    }

    const sections = page.map((el, k) => {

        const body = el.section.map((element, key) => {
            return (
                <motion.div
                    key={key + element.title}
                    className={classes.service}
                    initial={{ opacity: 0, translateX: '-50%' }}
                    whileInView={{ opacity: 1, translateX: '0%' }}
                >
                    <img src={'/img/' + element.image} alt={element.title} />
                    <h4>{element.title}</h4>
                    <p>{element.text}</p>
                </motion.div>
            );
        });

        return (
            <React.Fragment key={k + el.company}>
                <section className={classes.hero}>
                    <div className={classes.hero_content}>
                        <Carousel name={el.company} slogan={el.slogan} images={el.carousel} css={null}/>
                    </div>
                </section>
                <section
                    className={classes.services}
                >
                    <h3>{el.title}</h3>
                    <MotionConfig transition={{ duration: 1 }}>
                        {body}
                    </MotionConfig>
                </section>
            </React.Fragment>
        );
    });

    return (
        <main className={classes.PageTemplate}>
            <h1 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h1>
            {sections}
        </main>
    );
};

export default Home;