// REACT
import axios from 'axios';
import React, { useEffect, useState } from 'react';
// COMPONENTS
import Carousel from '../../components/Carousel/Carousel';
// SCSS
import classes from './Gallery.module.scss';
import CardComponent from '../../components/Card/Card';
import Modal from '../../components/UI/Modal/Modal';


interface Props {
    name: string,
    logo: string
}

interface Page{
    Gallery: {title: string, text: string, images:string[] }[]
}

const Home: React.FC<Props> = props => {

    const { name, logo } = props;

    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [page, pageHandler] = useState<Page | null>(null);

    const [images, imagesHandler] = useState<string[]>([]);

    const fetchData = async () => {
        const {data} = await axios('/gallery.json');
        pageHandler(data);
    }

    const setImages = (img: string[]) =>
    {
        imagesHandler(img)
    }

    let display = null;

    if(page)
    {
        display = page.Gallery.map((gal, key) => {
            return (
                <CardComponent key={gal.title + key} title={gal.title} text={gal.text} images={gal.images} btnText='Galerija' atClick={setImages} />
            );
        });
    }

    return (
        <main className={classes.PageTemplate}>
            <h1 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h1>
            <div className={classes.PageTemplate_box}>
                {display && display}
            </div> 
            {images.length > 0 && <Modal show={images.length > 0 && true} closeModal={() => imagesHandler([])}><Carousel images={images} slogan='' name='' css={classes.popup}/></Modal>}
        </main>
    );
};

export default Home;