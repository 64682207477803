// REACT
import React from "react";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// SCSS
import classes from './Card.module.scss';


interface CardProps {
    title: string,
    text: string,
    images: string[],
    btnText: string,
    atClick: (images: string[]) => void
}

const CardComponent: React.FC<CardProps> = props => {

    const { title, text, images, btnText, atClick } = props;

    return(
        <Card className={classes.Card}>
            <CardMedia
                sx={{ height: 240 }}
                image={images.length > 0 ? '/img/' + images[0] : ''}
                title={title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {text}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => atClick(images)}>{btnText}</Button>
            </CardActions>
        </Card>
    );
};

export default CardComponent;