// MODELS
import { Game_model } from '../../models/Game_model';
import { Provider_model, Vendor_model } from '../../models/Systems_model';
import { Game_details_model } from '../../models/Game_details_model';

export enum ActionType {
    GET_GAME_DETAILS = 'GET_GAME_DETAILS',
    GET_SYSTEMS = 'GET_SYSTEMS',
    GET_VENDORS = 'GET_VENDORS',
    GET_ROADMAP_GAMES = 'GET_ROADMAP_GAMES',
    CLEAR_ROADMAP_GAMES = 'CLEAR_ROADMAP_GAMES',
    LOG_IN = 'LOG_IN',
    LOG_OUT = 'LOG_OUT'
}

interface actionGetGameDetails {
    type: ActionType.GET_GAME_DETAILS;
    payload: Game_details_model;
}

interface actionGetSystems {
    type: ActionType.GET_SYSTEMS;
    payload: Provider_model[];
}

interface actionGetVendors {
    type: ActionType.GET_VENDORS;
    payload: Vendor_model[];
}

interface actionClearRoadmap {
    type: ActionType.CLEAR_ROADMAP_GAMES;
    payload: null;
}

interface actionGetRoadmap {
    type: ActionType.GET_ROADMAP_GAMES;
    payload: Game_model[] | null;
}

interface actionLogIn{
    type: ActionType.LOG_IN;
    payload: string;
}

interface actionLogOut{
    type: ActionType.LOG_OUT;
    payload: null;
}

export type Action = actionGetRoadmap | actionLogIn | actionLogOut | actionGetSystems | actionGetVendors | actionGetGameDetails | actionClearRoadmap;