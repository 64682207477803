// ACTIONTYPES
import { ActionType, Action } from '../action-types'
// MODELS
import { Game_details_model } from '../../models/Game_details_model';

// INITIAL STATE
const initial_state = {
    gametypes: null,
    volatilities: null,
    resolutions: null,
    payoutMechanics: null,
};

interface game_details_state {
    gametypes: string | null;
    volatilities: string | null;
    resolutions: string | null;
    payoutMechanics: string | null;
}

// REDUCER ACTION
const reducer = ( state: game_details_state = initial_state, action: Action ) => {
    console.log(state)
    if( action.type === ActionType.GET_GAME_DETAILS ) {
        return set_game_details( state, action );
    }else{
        return state;
    }
};

// 
const set_game_details = ( state: game_details_state, action: Action ) => {
    return action.payload;
};

export default reducer;