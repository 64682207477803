// REACT
import React from 'react';
// REDUX
// COMPONENTS

// SCSS
import classes from './Footer.module.scss';


const Footer: React.FC = props => {

    const { children } = props;

    return (
        <footer className={classes.Footer}>
            <div>
                <h1 className={classes.Footer_Title}>Your Business Name | All rights reserved &#169;{new Date().getFullYear()}.</h1>
            </div>
        </footer>
    );
};

export default Footer;