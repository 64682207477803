// REACT
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// ROUTING
import { BrowserRouter } from 'react-router-dom';
// REDUX
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
// REDUCERS
import systemsReducer from './store/reducers/systems';
import authReducer from './store/reducers/auth';
import roadmapReducer from './store/reducers/roadmap';
import gameDetailsReducer from './store/reducers/gameDetails';


const rootReducer = combineReducers({
  systems: systemsReducer,
  auth: authReducer,
  roadmap: roadmapReducer,
  gameDetails: gameDetailsReducer,
});

const store = createStore( rootReducer, applyMiddleware( thunk ) );

const app = (
  <React.StrictMode>
    <BrowserRouter>
      <Provider store = { store }>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

ReactDOM.render( app, document.getElementById( 'root' ) );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
