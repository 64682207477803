// REACT
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
// SCSS
import classes from './PageTableOne.module.scss';
// UTILITIES

// MODELS
import axios from 'axios';


interface Props {
    name: string,
    logo: string
}

const PageTableOne: React.FC<Props> = props => // PAGE TABLE
{

    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [columns, columnsHandler] = useState<GridColDef[]>([]);
    const [rows, rowsHandler] = useState([]);

    // Table
    const fetchData = async () => {
        const response = await axios('/table.json');
        rowsHandler(response.data);

        let createColumns: GridColDef[] = [];

        for (const key in response.data[0]) {
            createColumns.push({ field: key, headerName: key.charAt(0).toUpperCase() + key.slice(1), align: 'center', minWidth: 300, sortable: true, headerAlign: "center" })
        }

        columnsHandler(createColumns);
    }

    const { name,logo } = props;

    return (
        <div className={classes.PageTemplate}>
            <h2 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h2>
            <div className={classes.PageTemplate_box}>
                <Box sx={{ height: '70vh', minWidth: '100%', backgroundColor: 'white' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        classes={{ main: 'table' }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </Box>
            </div>
        </div>
    );
};

export default PageTableOne;