// REACT
import React from 'react';
// ANIMATIONS
import { motion } from 'framer-motion';
// CSS
import classes from './Backdrop.module.scss';

interface backdrop_props {
    atClick: () => void
}


const backdrop: React.FC<backdrop_props> = props => {

    const { atClick } = props;

    const backdropAnimation = {
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      };

    return (
        <motion.div 
            initial = "initial"
            animate = "in"
            exit = "out"
            variants = { backdropAnimation }
            className = { classes.Backdrop } 
            onClick = { atClick } 
        />
    );
};

export default backdrop;