// ACTIONTYPES
import { ActionType, Action } from '../action-types'
// MODELS
import { Provider_model, Vendor_model } from '../../models/Systems_model';

// INITIAL STATE
const initial_state = {
    providers: null,
    vendors: null
};

interface systems_state {
    providers: Provider_model[] | null;
    vendors: Vendor_model[] | null;
}

// REDUCER ACTION
const reducer = ( state: systems_state = initial_state, action: Action ) => {
    console.log(state)
    if( action.type === ActionType.GET_SYSTEMS ) {
        return set_systems( state, action );
    } else if( action.type === ActionType.GET_VENDORS ) {
        return set_vendors( state, action );
    }else{
        return state;
    }
};

// ONLOGIN
const set_systems = ( state: systems_state, action: Action ) => {
    return {
        ...state,
        providers: action.payload,
    };
};

const set_vendors = ( state: systems_state, action: Action ) => {
    return {
        ...state,
        vendors: action.payload,
    };
};

export default reducer;