// ACTIONTYPES
import { ActionType, Action } from '../action-types'

// INITIAL STATE
const initial_state = {
    user: null,
    user_email: null,
};

interface auth_state {
    user: string | null;
    user_email: string | null;
}

// REDUCER ACTION
const reducer = ( state: auth_state = initial_state, action: Action ) => {
    console.log(state)
    if( action.type === ActionType.LOG_IN ){
        return login( state, action );
    }else if( action.type === ActionType.LOG_OUT ){
        return logout();
    }else{
        return state;
    }
};

// ONLOGIN
const login = ( state: auth_state, action: Action ) => {
    return {
        user: action.payload,
    }
};
// ONLOGOUT
const logout = () => {
    return {
        user: null,
        userEmail: null,
    };
};

export default reducer;