// ACTIONTYPES
import { ActionType, Action } from '../action-types'
// MODELS
import { Game_model } from '../../models/Game_model';

// INITIAL STATE
const initial_state = {
    roadmap: null
};

interface roadmap_state {
    roadmap: Game_model[] | null;
}

// REDUCER ACTION
const reducer = ( state: roadmap_state = initial_state, action: Action ) => {
    console.log(state)
    if( action.type === ActionType.GET_ROADMAP_GAMES ){
        return set_roadmap_games( state, action );
    }else{
        return state;
    }
};

// ONLOGIN
const set_roadmap_games = ( state: roadmap_state, action: Action ) => {
    return {
        ...state,
        roadmap: action.payload,
    };
};

export default reducer;